import React, { useEffect, useMemo, useState } from "react";
import { MapContainer, TileLayer, Polygon, Popup, ZoomControl, Marker } from "react-leaflet";
import { polygonToCells, cellToBoundary } from "h3-js";
import "leaflet/dist/leaflet.css";
import NetworkService from "./NetworkService";
import colors from "./colors.json";
import { polygon } from "./belgradePolygon";
import { useLocation } from "react-router-dom";
import "leaflet-rotatedmarker";

const RIDE_REQUEST_SCALE = 2;

const App = () => {
  const center = [44.80317492488992, 20.45098200650307];
  const resolution = 8;

  const location = useLocation();
  const [queryParams, setQueryParams] = useState(new URLSearchParams(location.search));
  useEffect(() => {
    setQueryParams(new URLSearchParams(location.search));
  }, [location.search]);

  const driverLat = queryParams.get("driverLat") || 0;
  const driverLng = queryParams.get("driverLng") || 0;
  const rotation = queryParams.get("rotation") || 0;

  const carIcon = window?.L?.icon({
    iconUrl: "/cargo-car-pin.svg",
    className: "carIcon",
    iconSize: [32, 32],
    className: "carIcon",
  });

  const [mapData, setMapData] = useState(null);

  useEffect(() => {
    const fetchZonesData = () => {
      NetworkService.getZonesData()
        .then((zones) => {
          setMapData(zones);
        })
        .catch((error) => {
          console.error("Error fetching zones data:", error);
        });
    };
    fetchZonesData();
    const intervalId = setInterval(fetchZonesData, 30000);
    return () => clearInterval(intervalId);
  }, []);

  const hexagonToPolygon = (hex) => {
    const boundary = cellToBoundary(hex, true);
    return boundary.map(([lat, lng]) => [lng, lat]);
  };

  const polygons = useMemo(() => {
    if (!mapData) {
      return [];
    }
    const hexagons = polygonToCells([polygon], resolution);
    return hexagons.map((hex) => {
      const hexagonData = mapData.find((h) => h.h3_hash === hex);
      if (!hexagonData) {
        return null;
      }
      const requests = Math.round(hexagonData?.request_count / RIDE_REQUEST_SCALE);
      const color = colors[Math.min(requests, 20)] || "transparent";
      return <Polygon key={hex} positions={hexagonToPolygon(hex)} fillColor={color} weight={0} fillOpacity={0.5} />;
    });
  }, [mapData, resolution]);

  return (
    <MapContainer
      attributionControl={false}
      zoomControl={false}
      center={center}
      zoom={12}
      style={{ height: "100vh", width: "100%" }}
      maxZoom={14}
      minZoom={11}>
      <TileLayer attribution="CAR:GO" url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png" />
      <Marker
        rotationAngle={rotation}
        rotationOrigin="center center"
        icon={carIcon}
        position={[driverLat, driverLng]}
      />
      {polygons}
    </MapContainer>
  );
};

export default App;
