export const polygon = [
  [44.887614421661624, 20.24720205020921],
  [44.932117033757834, 20.36210517093894],
  [44.887170113212335, 20.47502276177314],
  [44.93144954052603, 20.590075314036145],
  [44.88627261672047, 20.702892516207516],
  [44.796864045165975, 20.700525642136274],
  [44.75263850638808, 20.585589204520407],
  [44.663108391963675, 20.58335344417042],
  [44.618714247002856, 20.468678822733505],
  [44.663796253689476, 20.356125352915107],
  [44.753438357252875, 20.35818099975586],
  [44.79821939369944, 20.245330407781925],
  [44.887614421661624, 20.24720205020921],
];
